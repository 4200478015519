import React from "react";
import { PropTypes } from "prop-types";
import icon from "../assets/article_icon.png";
import { Image } from "react-bootstrap";
import "./Article.css";

const Article = ({ article, isSelected, onClick }) => {
  return (
    <button
      type="button"
      className={"article-button btn btn-lg" + (isSelected ? " active" : "")}
      onClick={onClick}
    >
      <div className="article-icon">
        <Image src={icon} alt="decorative icon" />
      </div>
      <div className="article-button-text">{article.title}</div>
    </button>
  );
};

Article.propTypes = {
  article: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Article;
