import React from "react";
import titleImage from "./assets/welcome_page_final.png";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { motion } from "framer-motion";
import "./Welcome.css";
import CursorMenuItem from "./components/CursorMenuItem";
import { useHistory } from "react-router-dom";

const Prompt = () => (
  <motion.div
    className="prompt"
    initial={{ opacity: 1 }}
    animate={{ opacity: 0 }}
    transition={{
      repeat: Infinity,
      repeatType: "mirror",
      duration: 1,
      ease: "easeIn",
    }}
  >
    <p className="fw-bold fs-3 fs-sm-5 font-weight-bold text-wrap">
      Click here to start
    </p>
  </motion.div>
);

const Welcome = () => {
  const history = useHistory();

  // TODO: add onKeyDown for goMainPage
  function goMainPage() {
    history.push("/main");
    //TODO: move the routes to a common helper
  }

  return (
    <Container className="page-container bg-black rounded-3">
      <Container className="content-container bg-white rounded-3">
        <h1 className="header">
          <Image
            src={titleImage}
            className="title-image"
            alt="Welcome to CQ's blog"
            fluid
          />
        </h1>
        <CursorMenuItem
          className="cursor-menu"
          menuItem={<Prompt />}
          onClick={goMainPage}
        ></CursorMenuItem>
      </Container>
    </Container>
  );
};

export default Welcome;
