import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import MenuButton from "./MenuButton";
import { PropTypes } from "prop-types";

const MenuBar = ({ menuItems, onSelectedSectionChanged }) => {
  const [selected, setSelected] = useState(menuItems[0]);

  return (
    <div>
      <Row>
        {menuItems.map((element) => (
          <Col key={element.id}>
            <MenuButton
              text={element.text}
              isSelected={element.id === selected.id}
              onClick={(_e) => {
                setSelected(element);
                onSelectedSectionChanged(element); // TODO: make this more object oriented and type safe
              }}
            />
          </Col>
        ))}
        {/* <Col>
          <MenuButton text="Tech" isSelected />
        </Col>
        <Col>
          <MenuButton text="Art" />
        </Col>
        <Col>
          <MenuButton text="Snowboarding" />
        </Col> */}
      </Row>
    </div>
  );
};

MenuBar.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectedSectionChanged: PropTypes.func.isRequired,
};

export default MenuBar;
