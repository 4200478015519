import React, { useState } from "react";
import ImageGrid from "../components/ImageGrid";
import Modal from "../components/Modal";

const Art = () => {
  const [selectedImg, setSelectedImg] = useState(null); // TODO: move this to Art component

  return (
    <div>
      <ImageGrid setSelectedImg={setSelectedImg} />
      {selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </div>
  );
};

export default Art;
