import React from "react";
import cursor from "../assets/cursor_undertale.png";
import Image from "react-bootstrap/Image";
import "./Cursor.css";
import { PropTypes } from "prop-types";

const Cursor = ({ visible }) => {
  return (
    <Image
      className={"cursor" + (visible ? "" : " hidden")}
      src={cursor}
      alt="cursor"
    />
  );
};

Cursor.propTypes = {
  visible: PropTypes.bool,
};

export default Cursor;
