import React, { useState } from "react";
import { getPlaceholderArticles } from "../Main";
import { PropTypes } from "prop-types";
import Article from "../components/Article";
import "./Tech.css";

const Tech = ({ selectedMenu }) => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  return (
    <div className="tech-container">
      <div
        className={
          "article-titles" + selectedArticle ? " with-selected-article" : ""
        }
      >
        {getPlaceholderArticles(selectedMenu.text).map((article) => (
          <Article
            key={article.id}
            article={article}
            isSelected={selectedArticle && selectedArticle.id == article.id}
            onClick={() => setSelectedArticle(article)}
          />
        ))}
      </div>
      <div className="middle-divider"></div>
      <div className="selected-article-content">
        {selectedArticle && <p> {selectedArticle.body}</p>}
      </div>
    </div>
  );
};

Tech.propTypes = {
  selectedMenu: PropTypes.object.isRequired,
};

export default Tech;
