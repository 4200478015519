import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import "./Welcome.css";
import { Image } from "react-bootstrap";
import "./Main.css";
import MenuBar from "./components/MenuBar";
import Art from "./sections/Art";
import Tech from "./sections/Tech";
import logo from "./assets/simple_logo.png";
import owl from "./assets/owl.gif";

export const menuItems = [
  {
    id: "tech",
    text: "Tech",
    // route: "/tech",
  },
  {
    id: "art",
    text: "Art",
  },
  {
    id: "snowboarding",
    text: "Snowboarding",
  },
  {
    id: "collections",
    text: "Collections",
  },
];

function getBroadcastContent(sectionName) {
  // TODO: replace with the actual text
  return {
    title: sectionName,
    text:
      "You are currently in " +
      sectionName +
      " section. Use the button at the bottom to switch sections",
  };
}

export function getPlaceholderArticles(sectionName) {
  // Generate random article lists with random list length (5~45 articles)
  return [...Array(5 + Math.floor(Math.random() * 40)).keys()].map((index) => {
    return {
      id: sectionName + "article" + index,
      title: "Placeholder article " + index + " for " + sectionName,
      body: "hello world",
    };
  });
}

function getSectionContent(selectedMenu) {
  // TODO: create sections folder and move each section to a separate component
  // TODO: rewrite with typescript so that we can use enum here */
  switch (selectedMenu.id) {
    case "art":
      return <Art />;
    default:
      return <Tech selectedMenu={selectedMenu} />;
  }
}

const Main = () => {
  const [selectedMenu, setSelectedMenu] = useState(menuItems[0]); // TODO: make this less repetitive
  var broadcastContent = getBroadcastContent(selectedMenu.text);

  function onSelectedSectionChanged(selectedSection) {
    setSelectedMenu(selectedSection);
  }
  return (
    <Container className="main-white-background bg-white">
      <Container className="main-page-container bg-white">
        <div className="header">
          <Image className="header-image" src={logo} alt="CQ's Blog" />
          <div className="header-subtitle">Tech • Art • Snowboarding</div>
        </div>
        <div className="instruction">
          <Image
            className="instruction-mascot"
            src={owl}
            alt="instruciton mascot"
          />
          <div className="instruction-speech-bubble-triangle" />
          <div className="instruction-speech-bubble">
            {broadcastContent.text}
          </div>
        </div>
        {/* fix layout so that it extend until the footer */}
        <Container className="content-wrapper bg-white border border-dark">
          {getSectionContent(selectedMenu)}
        </Container>

        <Container className="footer">
          <MenuBar
            menuItems={menuItems}
            onSelectedSectionChanged={onSelectedSectionChanged}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default Main;
