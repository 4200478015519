import React from "react";
import Cursor from "./Cursor";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./CursorMenuItem.css";
import { PropTypes } from "prop-types";

const CursorMenuItem = ({ menuItem, onClick }) => {
  const [cursorVisible, setCursorVisible] = useState(false);
  return (
    // onMouseEnter and onMouseLeave is used to show the cursor when mouse is over the menu item
    // <div
    //   className="cursor-menu-item"
    //   onMouseEnter={(e) => setCursorVisible(true)}
    //   onMouseLeave={(e) => setCursorVisible(false)}
    // >
    //   <div className="cursor-container">
    //     <Cursor visible={cursorVisible} />
    //   </div>
    //   <div className="menu-item">{menuItem}</div>
    // </div>

    // TODO: This is not working well with responsive sizing
    // Replace this by using absolute cursor position and hooks
    // https://dev.to/joepurnell1/programmatically-positioning-elements-with-react-hooks-aef
    <Row
      className="cursor-menu-item d-flex align-items-center"
      onMouseEnter={(_e) => setCursorVisible(true)}
      onMouseLeave={(_e) => setCursorVisible(false)}
      onClick={(_e) => onClick()}
    >
      <Col className="spacing"></Col>
      {/* TODO: change to use overlay so that it can work well on mobile */}
      <Col className="cursor-container" md="auto">
        <Cursor visible={cursorVisible} />
      </Col>
      <Col className="col-6 menu-item">{menuItem}</Col>
      <Col className="spacing"></Col>
    </Row>
  );
};

CursorMenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CursorMenuItem;
