import React from "react";
import { motion } from "framer-motion";
import images from "../assets/artGallery";
import "./ImageGrid.css";
import { PropTypes } from "prop-types";

// TODO: make images a prop that passed in if we need to generalize the component
const ImageGrid = ({ setSelectedImg }) => {
  console.log(images);
  return (
    <div className="img-grid" initial={{ opcacity: 0 }} drag={1}>
      {images &&
        images.map((image) => (
          <motion.div
            className="img-wrap"
            key={image.filename}
            layout
            whileHover={{ opacity: 1, scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setSelectedImg(image)}
          >
            <motion.img
              src={image.src}
              alt="image alt text"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            />
          </motion.div>
        ))}
    </div>
  );
};

ImageGrid.propTypes = {
  setSelectedImg: PropTypes.func.isRequired,
};

export default ImageGrid;
