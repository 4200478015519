function importAll(r) {
  console.log("require images: " + r);
  let images = [];
  r.keys().map((item, _index) => {
    images.push({
      filename: item.replace("./", ""),
      src: r(item),
    });
    // images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(require.context("./", false, /\.(png|jpe?g|svg)$/));

export default images;
