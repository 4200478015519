import React from "react";
import Cursor from "./Cursor";
import "./CursorMenuItem.css";
import { PropTypes } from "prop-types";
import icon from "../assets/button_icon.png";
import { Image } from "react-bootstrap";
import "./MenuButton.css";

const MenuButton = ({ text, isSelected, onClick }) => {
  return (
    <button
      type="button"
      className={
        "menu-button btn btn-outline-dark btn-lg" +
        (isSelected ? " active" : "")
      }
      onClick={onClick}
    >
      <div className="icon">
        {isSelected ? (
          <Cursor visible />
        ) : (
          <Image src={icon} alt="decorative icon" />
        )}
      </div>
      <div className="button-text">{text}</div>
    </button>
  );
};

MenuButton.propTypes = {
  text: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default MenuButton;
